import styled from 'styled-components'
import { device } from '../../../theme'

export const SectionWrap = styled.section`
  position: relative;
  padding-top: 58px;
  padding-bottom: 100px;
  z-index: 5;
  text-align: center;

  @media ${device.medium} {
    padding-top: 70px;
  }

  @media ${device.small} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`
