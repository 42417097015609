import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form/dist/index.ie11'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Button from '../../../components/ui/button'
import Alert from '../../../components/ui/alert'
import NewsletterImage from '../../../assets/images/icons/newsletter.svg'
import { Input, Error, FormGroup } from '../../../components/ui/form'
import { SectionWrap, StyledText } from './style'

const Newsletter = () => {
  const apiUrl =
    'https://prod-240.westeurope.logic.azure.com:443/workflows/0c8620faff6c456aa635134e868ddf46/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=_ANcmuCchODl1LS5E9Yy8GxXZ5HAzWUKaGBhL2g7KN0'

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })

    if (ok) {
      form.reset()
    }
  }

  const onSubmit = (data, event) => {
    const form = event.target
    setServerState({ submitting: true })

    axios({
      method: 'post',
      url: apiUrl,
      data: data,
    })
      .then((response) => {
        setServerState({ submitting: false })
        handleServerResponse(true, 'Uw aanmelding is succesvol verzonden', form)
      })
      .catch((response) => {
        console.log(response.response.data.error)
        setServerState({ submitting: false })
        handleServerResponse(
          false,
          'Er is iets misgegaan met het verzenden van uw aanvraag. Probeer het later nogmaals',
          form,
        )
      })
  }

  return (
    <SectionWrap className="container-xl">
      <Container>
        <Row alignitems="center">
          <Col md={5}>
            <img
              src={NewsletterImage}
              alt="Meld je aan voor onze nieuwsbrief"
              title="Meld je aan voor onze nieuwsbrief"
            />
          </Col>

          <Col md={6}>
            <Heading as="h2" mb="16px">
              Meld je aan voor onze nieuwsbrief
            </Heading>

            {serverState.status && (
              <Alert
                variant={serverState.status.ok ? 'success' : 'danger'}
                mb="30px"
              >
                {serverState.status.ok ? (
                  <FaCheck className="icon" role="img" />
                ) : (
                  <FaTimes className="icon" role="img" />
                )}
                {serverState.status.msg}
              </Alert>
            )}

            <StyledText>
              Op de hoogte blijven van ons laatste nieuws? Meld u dan aan voor
              onze e-mailnieuwsbrief #KrachtigeRegio.
            </StyledText>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
              <Row>
                <Col lg="8">
                  <FormGroup>
                    <Input
                      bgcolor="white"
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      placeholder="Voer uw e-mailadres in*"
                      aria-label="Voer uw e-mailadres in"
                      ref={register({
                        required: 'Het email veld is niet ingevuld',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message:
                            'Het ingevoerde emailadress lijkt niet te kloppen',
                        },
                      })}
                    />
                    <Error>{errors.email && errors.email.message}</Error>
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <Button type="submit" disabled={serverState.submitting}>
                    Aanmelden
                  </Button>
                </Col>
              </Row>
            </form>

            <StyledText mt="10px" as="small">
              U geeft ons toestemming voor het toesturen van de door u
              aangegeven nieuwsbrieven.
            </StyledText>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

export default Newsletter
