import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import Header from '../containers/header'
import Footer from '../containers/footer'
import Hero from '../components/hero'
import BoxImageLarge from '../containers/elements/box-large-image/section-home'
import PageSlider from '../containers/page-slider'
import Newsletter from '../containers/news/newsletter'
import Socials from '../containers/news/social'
import Events from '../containers/events'

import NewsImage from '../assets/images/icons/news/news.svg'
import PublicationImage from '../assets/images/icons/news/publication.svg'
import EventImage from '../assets/images/icons/news/event.svg'
import MonitorImage from '../assets/images/icons/news/monitor.svg'
import NewsletterImage from '../assets/images/icons/news/newsletter.svg'

const IndexPage = ({ location, pageContext }) => {
  const pageData = useStaticQuery(graphql`
    query NewspageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      page: allContentfulPagina(
        filter: { id: { eq: "ff6ebffa-240c-5d3c-a1bb-86ea241410fc" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }

      articles: allContentfulPublicatie(
        limit: 4
        sort: { fields: date, order: DESC }
        filter: {
          title: { ne: null }
          slug: { regex: "/^(?!untitled-entry).+/" }
          date: { ne: null }
        }
      ) {
        edges {
          node {
            id
            title
            date(locale: "nl_NL", formatString: "DD MMMM YYYY")
            slug
            image {
              fluid(maxWidth: 260, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const allCategories = [
    {
      id: 1,
      title: 'Nieuws',
      description: 'Blijf op de hoogte van ons laatste nieuws.',
      url: '/actueel/nieuws',
      icon: NewsImage,
    },
    {
      id: 2,
      title: 'Publicaties',
      description:
        'Alle belangrijke publicaties van RWB of waar RWB aan meewerkte.',
      url: '/actueel/publicaties',
      icon: PublicationImage,
    },
    {
      id: 3,
      title: 'Agenda',
      description:
        'Bekijk alle evenementen die RWB organiseert of waar RWB bij betrokken is.',
      url: '/actueel/agenda',
      icon: EventImage,
    },
    {
      id: 4,
      title: 'Dataloket',
      description:
        'Interessante data, cijfers en gegevens over West-Brabant vindt u hier.',
      url: '/actueel/dataloket-west-brabant',
      icon: MonitorImage,
    },
    {
      id: 5,
      title: 'Nieuwsbrief',
      description:
        'Op de hoogte blijven van de laatste ontwikkeling van en rondom Regio West-Brabant? Abonneer je dan op onze nieuwsbrief #KrachtigeRegio.',
      url: '/actueel/nieuwsbrief',
      icon: NewsletterImage,
    },
  ]

  const metadata = pageData.site.siteMetadata
  metadata.url = `${metadata.siteUrl}/actueel`
  const latestArticles = pageData.articles.edges.map((edge) => edge.node)
  const {
    headerTitel,
    headerSubtitel,
    headerButtonTekst,
    headerButtonLink,
    headerImage,
  } = pageData.page.edges[0].node
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  return (
    <>
      <SEO
        title={`${headerTitel}`}
        description={headerSubtitel || headerTitel}
        image={headerImage?.file?.url}
        metadata={metadata}
      />

      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Hero
          flyoutHandler={flyoutHandler}
          title={headerTitel}
          description={headerSubtitel}
          buttontext={headerButtonTekst}
          buttonlink={headerButtonLink}
          image={headerImage}
          pageContext={pageContext}
        />

        <PageSlider list={allCategories} offset={true} />
        <BoxImageLarge split={true} />

        <BoxImageLarge
          path="/actueel/publicaties"
          title="Recente publicaties"
          buttontext="publicaties"
          detailButtonText="Bekijk publicatie"
          items={latestArticles}
          padding={false}
        />

        <Events
          title="Agenda"
          path="/actueel/agenda"
          buttonText="agenda items"
        />
        <Newsletter />
        <Socials />
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
