import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from 'react-icons/fa'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import { SectionWrap } from './style'
import Heading from '../../../components/ui/heading'
import Social, { SocialLink } from '../../../components/ui/social'

const Socials = () => {
  const CompanySocial = useStaticQuery(graphql`
    query FollowSocialQuery {
      company: allContentfulBedrijf(
        filter: { id: { eq: "ca7bb2ca-5d8a-531e-a88e-80dc8c9e272b" } }
      ) {
        edges {
          node {
            linkedIn
            facebook
            twitter
            youtube
            instagram
          }
        }
      }
    }
  `)

  const SocialList = CompanySocial.company.edges[0].node

  return (
    <SectionWrap className="container-xl">
      <Container>
        <Row>
          <Col lg={12}>
            <Heading as="h3" mb="26px">
              Volg ons
            </Heading>

            <Social varient="outlined" shape="rounded" tooltip={true}>
              {SocialList.linkedIn && (
                <SocialLink path={SocialList.linkedIn} title="LinkedIn">
                  <FaLinkedin role="img" />
                </SocialLink>
              )}

              {SocialList.youtube && (
                <SocialLink path={SocialList.youtube} title="YouTube">
                  <FaYoutube role="img" />
                </SocialLink>
              )}

              {SocialList.twitter && (
                <SocialLink path={SocialList.twitter} title="Twitter">
                  <FaTwitter role="img" />
                </SocialLink>
              )}

              {SocialList.facebook && (
                <SocialLink path={SocialList.facebook} title="Facebook">
                  <FaFacebookSquare role="img" />
                </SocialLink>
              )}

              {SocialList.instagram && (
                <SocialLink path={SocialList.instagram} title="Instagram">
                  <FaInstagram role="img" />
                </SocialLink>
              )}
            </Social>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

export default Socials
