import styled from 'styled-components'
import { device } from '../../../theme'
import Text from '../../../components/ui/text'

export const StyledText = styled(Text)`
  color: ${(props) => props.theme.colors.text} !important;
`

export const SectionWrap = styled.section`
  position: relative;
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
  z-index: 5;
  background-color: ${(props) => props.theme.colors.background};

  @media ${device.medium} {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @media ${device.small} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  input {
    min-height: 48px;
  }

  button {
    width: 100%;

    @media ${device.medium} {
      margin-top: 16px;
    }
  }

  p {
    font-size: ${(props) => props.theme.fontSizes.introLarge};
    line-height: ${(props) => props.theme.lineHieghts.introLarge};

    @media ${device.small} {
      font-size: ${(props) => props.theme.fontSizesSmall.introLarge};
      line-height: ${(props) => props.theme.fontSizesSmall.introLarge};
    }
  }

  small {
    display: block;
  }

  p,
  small {
    color: #999999;
  }

  img {
    display: block;
    width: 100%;

    @media ${device.small} {
      display: none;
    }
  }

  .col:last-child {
    flex: 0 1;
  }
`
